<template>
    <div>
        <v-row class="pt-3 px-3">
            <v-col md="8" cols="12">
                <v-card>
                    <v-form ref="form" v-model="formValid">
                        <v-card-title
                            class="headline grey lighten-2"
                            primary-title
                        >
                            Nueva solicitud de servicio
                        </v-card-title>
                        <v-card-text>
                            <v-row class="pt-3 px-3">
                                <v-col md="5" cols="12">
                                    <div class="text-h6">Solicitud</div>
                                    <v-select
                                        v-model="dat.area"
                                        label="Área que solicita"
                                        :items="areas"
                                        item-text="area"
                                        item-value="id"
                                        rounded
                                        filled
                                        dense
                                        :rules='[value => !!value || "Requerido",]'
                                        @click="cargaAreas"
                                    />
                                    <div>Tipo de servicio solicitado</div>
                                    <v-radio-group
                                        v-model="dat.tipo"
                                        dense
                                    >
                                        <v-radio label="Biomédica" :value="1"/>
                                        <v-radio label="Mantenimiento" :value="2"/>
                                        <v-radio label="Tecnologías" :value="3"/>
                                    </v-radio-group>

                                    <v-slide-y-transition>
                                        <v-checkbox
                                            v-if="dat.tipo==1"
                                            label="Solicitud de insumos"
                                            v-model="dat.insumos"
                                            dense
                                        />
                                    </v-slide-y-transition>

                                    <v-slide-y-transition>
                                        <v-text-field
                                            v-model="dat.servicio"
                                            v-if="!dat.insumos"
                                            label="Servicio solicitado"
                                            rounded
                                            filled
                                            dense
                                            :rules='[value => !!value || "Requerido",]'
                                        />
                                    </v-slide-y-transition>

                                    <v-slide-y-transition>
                                        <v-textarea
                                            v-model="dat.descripcion"
                                            v-if="!dat.insumos"
                                            label="Descripción de la solicitud"
                                            rounded
                                            filled
                                            dense
                                            :rules='[value => !!value || "Requerido",]'
                                        />
                                    </v-slide-y-transition>

                                </v-col>

                                <!--
                                    Para solicitud POR SERVICIO
                                -->
                                <v-fade-transition>
                                    <v-col md="7" cols="12" v-if="!dat.insumos">
                                        <div class="text-h6">Datos adicionales (opcional)</div>
                                        <v-text-field
                                            v-model="dat.equipo"
                                            label="Equipo reportado"
                                            rounded
                                            filled
                                            dense
                                        />
                                        <v-text-field
                                            v-model="dat.marca"
                                            label="Marca"
                                            rounded
                                            filled
                                            dense
                                        />
                                        <v-text-field
                                            v-model="dat.modelo"
                                            label="Modelo"
                                            rounded
                                            filled
                                            dense
                                        />
                                        <v-text-field
                                            v-model="dat.serie"
                                            label="Serie"
                                            rounded
                                            filled
                                            dense
                                        />
                                        <v-textarea
                                            v-model="dat.observaciones"
                                            label="Obervaciones adicionales"
                                            rounded
                                            filled
                                            dense
                                        />
                                    </v-col>
                                </v-fade-transition>

                                <!--
                                    Para solicitud DE INSUMOS
                                -->
                                <v-fade-transition>
                                    <v-col md="7" cols="12" v-if="dat.insumos">
                                        <v-data-table
                                            elevation="2"
                                            :headers="headers"
                                            :items="itemsInsumos"
                                            disable-sort
                                            item-key="id"
                                            class="elevation-1"
                                            hide-default-footer
                                        >

                                            <template v-slot:top>
                                                <v-icon @click="modalInsumos=true">
                                                    mdi-plus
                                                </v-icon>
                                            </template>

                                            <template v-slot:[`item.acciones`]="{ item }">
                                                <v-btn icon @click="borra(item.id)">
                                                    <v-icon color="red">mdi-cancel</v-icon>
                                                </v-btn>
                                            </template>
                                        </v-data-table>
                                    </v-col>
                                </v-fade-transition>
                            </v-row>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer/>
                            <v-btn color="green" :disabled="!formValid" class="white--text">
                                Solicitar
                            </v-btn>
                        </v-card-actions>
                    </v-form>
                </v-card>
            </v-col>
            <v-col md="4" cols="12">
                <v-card>
                    <v-card-title
                        class="headline grey lighten-2"
                        primary-title
                    >
                        Buscar por folio
                    </v-card-title>
                    <v-card-text>
                        <v-text-field
                            class="mt-5"
                            v-model="bus.folio"
                            label="Folio de la solicitud"
                            rounded
                            filled
                            dense
                        />
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer/>
                        <v-btn color="green" dark>
                            BUSCAR
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>
        <agrega-insumo
            :dialogModal.sync="modalInsumos"
        ></agrega-insumo>
    </div>
</template>

<script>
export default {
    components:{
        'agrega-insumo':()=>import('@/components/mtto/MttoBIomedicaModalInsumos'),
    },
    data:()=>({
        // General de la solicitud
        formValid:false,
        dat:{},
        bus:{},
        areas:[],

        // para el dataTable
        itemsInsumos:[],
        headers:[
            { text: 'Cantidad', value: 'clave', width:'10%'},
            { text: 'Concepto', value: 'diag'},
            { text: '', value: 'acciones', width:'10%'},
        ],

        // Para el modal de los insumos
        modalInsumos:false,
    }),

    methods:{
        borra(id){
            console.log(id)
        },
        async cargaAreas(){
            try{
                const req = await this.$http({
                    url:'/mantenimiento/subAreaList',
                    method:'GET',
                })
                this.areas = req.data
            }catch(err){
                this.$store.dispatch('security/revisaError',err)
            }
        },
    },

    watch:{
        'dat.tipo'(v){
            if (v!=1){
                this.dat.insumos=false
            }
        },
    },

}
</script>

<style>

</style>